import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBhhEA71B1Sqv3C5uHnkwlsB4xbEqYOZ0o",
  authDomain: "tryrowy.firebaseapp.com",
  projectId: "tryrowy",
  storageBucket: "tryrowy.appspot.com",
  messagingSenderId: "87786613370",
  appId: "1:87786613370:web:ea518b831467fb652b29c3",
  measurementId: "G-3CJZM9HKMD",
};

const rowyServiceApp = initializeApp(firebaseConfig, "rowy-service");
export const analytics = getAnalytics(rowyServiceApp);

export { logEvent };
