import { useAtom } from "jotai";
import { colord } from "colord";

import {
  useScrollTrigger,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  Typography,
  Grow,
  Fade,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuCloseIcon from "@mui/icons-material/MenuOpen";
import GitHubIcon from "@mui/icons-material/GitHub";
import { Discord as DiscordIcon } from "@src/assets/icons";
import TwitterIcon from "@mui/icons-material/Twitter";

import Logo from "@src/assets/Logo";
import { NAV_DRAWER_WIDTH, NAV_DRAWER_COLLAPSED_WIDTH } from "./NavDrawer";
import UserMenu from "./UserMenu";
import UpdateCheckBadge from "./UpdateCheckBadge";

import { projectScope, userRolesAtom } from "@src/atoms/projectScope";
import { ROUTE_TITLES } from "@src/constants/routes";
import { EXTERNAL_LINKS } from "@src/constants/externalLinks";

export const TOP_BAR_HEIGHT = 56;

export interface ITopBarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  isPermanent: boolean;
  routeTitle: typeof ROUTE_TITLES[keyof typeof ROUTE_TITLES];
  title: string;
}

export default function TopBar({
  open,
  setOpen,
  isPermanent,
  routeTitle,
  title,
}: ITopBarProps) {
  const [userRoles] = useAtom(userRolesAtom, projectScope);

  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });
  const menuIcon = open ? <MenuCloseIcon /> : <MenuIcon />;

  return (
    <AppBar
      position="sticky"
      color="inherit"
      elevation={trigger ? 1 : 0}
      sx={{
        height: TOP_BAR_HEIGHT,
        backgroundColor: trigger
          ? (theme) =>
              colord(theme.palette.background.paper)
                .mix("#fff", 0.09) // Elevation 8
                .alpha(1)
                .toHslString()
          : "transparent",
        transitionProperty: "box-shadow, background-color",
      }}
    >
      <Toolbar
        sx={{
          height: TOP_BAR_HEIGHT,
          minWidth: 0,
          maxWidth: "none",
          "&&": {
            minHeight: TOP_BAR_HEIGHT,
            p: 0,
            pl: (theme) =>
              `max(env(safe-area-inset-left), ${theme.spacing(0.5)})`,
            pr: (theme) =>
              `max(env(safe-area-inset-right), ${theme.spacing(2)})`,
          },
        }}
      >
        <Grow in>
          <IconButton
            aria-label={`${open ? "Close" : "Open"} navigation drawer`}
            onClick={() => setOpen(!open)}
            size="large"
          >
            {userRoles.includes("ADMIN") ? (
              <UpdateCheckBadge>{menuIcon}</UpdateCheckBadge>
            ) : (
              menuIcon
            )}
          </IconButton>
        </Grow>

        {isPermanent && (
          <Box
            sx={{
              width: open ? NAV_DRAWER_WIDTH : NAV_DRAWER_COLLAPSED_WIDTH,
              height: 2,

              transform: `scale(${open ? 1 : 0})`,
              transformOrigin: "0% 50%",
              opacity: open ? 1 : 0,

              transitionProperty: "width, opacity, transform",
              transitionTimingFunction: "var(--nav-transition-timing-function)",
              transitionDuration: "var(--nav-transition-duration)",

              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              // pointerEvents: "none",
            }}
          >
            <a href="https://rowy.io" target="_blank" rel="noopener noreferrer">
              <Logo
                style={{
                  marginLeft: 1,
                  position: "relative",
                  zIndex: 1,
                  display: "block",
                }}
              />
            </a>
          </Box>
        )}

        <Fade
          in
          key={title}
          {...(typeof routeTitle !== "string"
            ? routeTitle.titleTransitionProps
            : undefined)}
        >
          <Box
            sx={[
              {
                flex: 1,
                overflowX: "auto",
                scrollbarWidth: "thin",
                userSelect: "none",
                ml: 1,
              },
              !(routeTitle as any)?.leftAligned && {
                ml: { xs: 1, sm: 40 / 8 + 1, md: (268 - 48) / 8 + 1 },
              },
              isPermanent &&
                (routeTitle as any)?.leftAligned && {
                  ml: -NAV_DRAWER_COLLAPSED_WIDTH / 8 + 0.5 + 2,
                },
            ]}
          >
            {typeof routeTitle !== "string" ? (
              routeTitle.titleComponent(open, isPermanent)
            ) : (
              <Typography
                variant="h6"
                component="h1"
                noWrap
                sx={{
                  flexShrink: 0,
                  flexGrow: 1,
                  typography: { sm: "h5" },
                  textAlign: { xs: "left", sm: "center" },
                }}
              >
                {title}
              </Typography>
            )}
          </Box>
        </Fade>

        <Grow in>
          <div>
            <IconButton
              href={EXTERNAL_LINKS.discord}
              target="_blank"
              rel="noopener noreferrer"
              title="Join the community on Discord"
              sx={{ mx: 0.25 }}
              children={<DiscordIcon />}
            />
          </div>
        </Grow>
        <Grow in>
          <div>
            <IconButton
              href={EXTERNAL_LINKS.twitter}
              target="_blank"
              rel="noopener noreferrer"
              title="Follow us on Twitter"
              sx={{ mx: 0.25 }}
              children={<TwitterIcon />}
            />
          </div>
        </Grow>
        <Grow in>
          <div>
            <IconButton
              href={EXTERNAL_LINKS.gitHub}
              target="_blank"
              rel="noopener noreferrer"
              title="Star us on GitHub"
              sx={{ mx: 0.25 }}
              children={<GitHubIcon />}
            />
          </div>
        </Grow>

        <Grow in>
          <div>
            <Button
              variant="contained"
              color="primary"
              href="https://rowy.app"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                ml: 1,
                minWidth: 80,

                bgcolor: "transparent",
                backgroundImage:
                  "linear-gradient(180deg,hsla(0,0%,100%,.4),hsla(0,0%,100%,0) 100%),linear-gradient(180deg,hsla(0,0%,100%,.2),hsla(0,0%,100%,.2)),linear-gradient(55deg,#fa0,#fd66cb 25%,#9f6eff 50%,#0af 75%,#0fa)",
                color: "#000",
                textShadow: "0 1px 0 rgba(255, 255, 255, 0.25)",

                "& .MuiTouchRipple-root": { color: "#fff" },
              }}
              endIcon="→"
            >
              Get Started
            </Button>
          </div>
        </Grow>
      </Toolbar>
    </AppBar>
  );
}
