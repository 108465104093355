import { atom, useAtom } from "jotai";

import Modal from "@src/components/Modal";
import Logo from "@src/assets/Logo";
import { Typography, Button, DialogContentText } from "@mui/material";

import { projectScope } from "@src/atoms/projectScope";

const demoLockAtom = atom(false);
export const useDemoLock = () => {
  const [, setOpen] = useAtom(demoLockAtom, projectScope);
  return () => setOpen(true);
};

export default function DemoLock() {
  const [open, setOpen] = useAtom(demoLockAtom, projectScope);

  return (
    <Modal
      open={open}
      fullScreen={false}
      onClose={() => setOpen(false)}
      title={
        <Logo
          size={2}
          style={{
            margin: "16px auto",
            display: "block",
            position: "relative",
            right: 44 / -2,
          }}
        />
      }
      maxWidth="xs"
      body={
        <>
          <Typography variant="h5" paragraph align="center">
            Thanks for trying Rowy!
          </Typography>

          <DialogContentText variant="body1" paragraph textAlign="center">
            We hope you’ve been enjoying your experience with Rowy so far.
          </DialogContentText>

          <DialogContentText variant="body1" paragraph textAlign="center">
            You can easily setup Rowy and start building using this template.
          </DialogContentText>

          <Button
            href="https://deploy.rowy.app/"
            target="_blank"
            rel="noopener noreferrer"
            variant="contained"
            color="primary"
            size="large"
            style={{ display: "flex" }}
          >
            Get started with Rowy
          </Button>
        </>
      }
    />
  );
}
